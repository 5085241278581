import React from 'react'
import { SafeAreaView, StyleSheet } from 'react-native'

import AppLoading from 'expo-app-loading'

import Navigation from '@views/navigation'
import { useCustomFonts } from '@views/hooks'
import { Modal, ModalRef } from '@views/components'

import { Auth0Provider } from '@auth0/auth0-react'
import {
  AccountContextProvider,
  AuthContextProvider,
  DisclaimerContextProvider,
  DepositsContextProvider,
  MemberCardsContextProvider,
  StatementsContextProvider,
  CameraPermissionContextProvider,
} from '@views/contexts'
import { AUTH0_AUDIENCE, AUTH0_CLIENT_ID, AUTH0_DOMAIN, FULL_STORY_ORG_ID } from '@assets/constants'
import * as FullStory from '@fullstory/browser'

export default function App() {
  let [fontsLoaded] = useCustomFonts()

  if (!fontsLoaded) return <AppLoading />

  FullStory.init({ orgId: FULL_STORY_ORG_ID, devMode: true }, ({ sessionUrl }) =>
    console.log(`Started session: ${sessionUrl}`)
  )

  return (
    <Auth0Provider
      domain={AUTH0_DOMAIN}
      clientId={AUTH0_CLIENT_ID}
      redirectUri={window.location.origin}
      audience={AUTH0_AUDIENCE}
      useRefreshTokens
      cacheLocation={'localstorage'}>
      <AuthContextProvider>
        <DisclaimerContextProvider>
          <AccountContextProvider>
            <DepositsContextProvider>
              <MemberCardsContextProvider>
                <StatementsContextProvider>
                  <CameraPermissionContextProvider>
                    <SafeAreaView style={styles.wrapper}>
                      <Navigation />
                      <Modal
                        ref={(ref) => {
                          ModalRef.ref = ref
                        }}
                      />
                    </SafeAreaView>
                  </CameraPermissionContextProvider>
                </StatementsContextProvider>
              </MemberCardsContextProvider>
            </DepositsContextProvider>
          </AccountContextProvider>
        </DisclaimerContextProvider>
      </AuthContextProvider>
    </Auth0Provider>
  )
}

const styles = StyleSheet.create({
  wrapper: {
    height: '100%',
  },
})
